// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'

// helpers
import { up, down, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData, extractNodesFromDataApplyLangs } from '../lib/helpers'
import { applyLang, applyLangSingle } from '../lib/intl'
import injectIntl from '../lib/intl/injectIntl'
import { enhanceContent } from '../lib/post'

// components
import T from '../lib/intl/T'
import { SEO, Title, Text, Gap, Row, Col, RoundedImage, Link, HexaImage } from '../components'

import listItemMarkerSrc from '../data/images/list-item-marker.svg'
import { useContentImages } from '../hooks/useContentImages'

const OfferContent = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    & > .p {
      margin-bottom: 24px;
    }
    /* // TODO - toto by možná bylo dobré mít i na blogu */
    li {
      color: ${colors.text};
      font-family: ${fonts.muli};
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      padding-left: 6px;
    }
    ul {
      list-style-image: url(${listItemMarkerSrc});
      padding-left: 16px;
      margin-top: 0;
      margin-bottom: 24px;
    }
    & > h2 {
      color: ${colors.blue};
      font-family: ${fonts.signika};
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      margin: 0;
      padding: 0;
      margin-bottom: 8px;
    }
    a {
      color: ${colors.green};

      &:hover {
        color: ${colors.darkGreen};
        text-decoration: none;
      }
    }
  `}
`

const ContactEmail = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    color: #00b3a6;
    font-family: ${fonts.signika};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  `}
`

const Line = styled.div`
  height: 0;
  border-bottom: 2px dashed #00b3a6;
  opacity: 0.3;
  width: 100%;
`

const JobRow = styled(Row)`
  min-height: 60px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px;
`

function JobOfferTemplate(props) {
  const {
    intl: { language, t },
    location: { pathname },
    data,
  } = props

  const { offer, offers } = extractNodesFromDataApplyLangs(data, language)

  const otherOffers = offers.filter(({ _id }) => _id !== offer._id)

  const contentImages = useContentImages()

  const contentReactElement = enhanceContent({
    content: offer.content,
    contentImages,
  })

  const { contactPerson } = offer

  return (
    <>
      <SEO
        title={`${offer.title} · ${t('Career - meta title')}`}
        pathname={pathname}
        description={offer.metaDesc}
      />
      <Gap.Top />
      <Row justifyContent="space-between" responsive bp="menu">
        <Col maxWidth="625px">
          <Title.PageTitle>{offer.title}</Title.PageTitle>
          <Gap gap="40px" mobileGap="24px" />
          <OfferContent>{contentReactElement}</OfferContent>

          <Gap gap="16px" mobileGap="0" />
          <Title.MediumTitle>
            <T>Zašli nám svůj strukturovaný životopis</T>
          </Title.MediumTitle>
          <Gap gap="14px" />
          <Row alignItems="center">
            <Col width="110px">
              <HexaImage image={contactPerson.photo.filename.image.gatsbyImageData} />
            </Col>
            <Gap gap="16px" />
            <Col>
              <Title.TinyTitle>{contactPerson.name}</Title.TinyTitle>
              <Text.Italic>{contactPerson.position}</Text.Italic>
              <Gap gap="6px" />
              <ContactEmail>{contactPerson.email}</ContactEmail>
            </Col>
          </Row>
        </Col>

        <Gap gap="24px" mobileGap="80px" bp="menu" />

        <Col maxWidth="335px">
          <Title.SmallGrayTitle>
            <T>Další volné pozice:</T>
          </Title.SmallGrayTitle>
          <Gap gap="14px" />
          <Line />
          {otherOffers.map(({ title, slug }) => (
            <Fragment key={slug}>
              <Link width="100%" to={`${Link.CAREER}/${slug}`}>
                <JobRow>
                  <Title.TinyTitle>{title}</Title.TinyTitle>
                </JobRow>
              </Link>
              <Line />
            </Fragment>
          ))}
        </Col>
      </Row>

      <Gap gap="80px" mobileGap="80px" bp="menu" />
    </>
  )
}

export default injectIntl(JobOfferTemplate)

export const pageQuery = graphql`
  query ($id: String!) {
    offer: joboffers(id: { eq: $id }) {
      ...JobOfferFragment
    }

    offers: allJoboffers(
      sort: { fields: createdAt, order: DESC }
      filter: { state: { eq: "published" } }
    ) {
      edges {
        node {
          ...JobOfferFragment
        }
      }
    }

    # contentImages: allFile(filter: { relativePath: { regex: "/^uploaded-image-/" } }) {
    #   edges {
    #     node {
    #       ...PostContentImage
    #       relativePath
    #     }
    #   }
    # }
  }
`
